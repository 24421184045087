import {
    createProject,
    getAllProjects,
    getProject,
    updateProject,
    addUserToProject,
    removeUserFromProject,
    deleteProject,
    addTask,
    updateTask,
    deleteTask,
    reorderTasks,
    getMessages,
    sendMessage
} from "@/services/project.service";
import { uploadFile, getFile, downloadFile, deleteFile, downloadAllFiles } from '@/services/file.service';
import { useToast } from "vue-toastification";

const state = () => ({
    project: {},
    projects: [],
    projectError: null,
    messages: []
});

const mutations = {
    setProject(state, project) {
        state.project = project;
    },
    setProjects(state, projects) {
        state.projects = projects;
    },
    setProjectError(state, error) {
        state.projectError = error;
    },
    reorderTasks(state, { projectId, tasks }) {
        const project = state.projects.find(p => p._id === projectId);
        if (project) {
            project.tasks = tasks;
        }
    },
    setMessages(state, messages) {
        state.messages = messages;
    },
    addMessage(state, message) {
        state.messages.push(message);
    }
};

const actions = {
    async fetchProject({ commit }, id) {
        const toast = useToast();

        try {
            const project = await getProject(id);
            commit('setProject', project);
        } catch (err) {
            commit('setProjectError', err);
            toast.error("Ошибка при загрузке проекта");
        }
    },
    async createProject({ dispatch }, data) {
        const toast = useToast();

        try {
            await createProject(data);
            dispatch('fetchProjects');
            toast.success("Проект успешно создан");
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при создании проекта");
        }
    },
    async updateProject({ dispatch }, { id, key, data }) {
        const toast = useToast();

        try {
            await updateProject(id, key, data);
            dispatch('fetchProjects');
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при изменении проекта");
        }
    },
    async addUserToProject({ dispatch }, { projectId, userId }) {
        const toast = useToast();

        try {
            await addUserToProject(projectId, userId);
            dispatch('fetchProject', projectId);
            toast.success("Пользователь успешно добавлен в проект");
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при добавлении пользователя в проект");
        }
    },
    async removeUserFromProject({ dispatch }, { projectId, userId }) {
        const toast = useToast();

        try {
            await removeUserFromProject(projectId, userId);
            dispatch('fetchProject', projectId);
            toast.success("Пользователь успешно удален из проекта");
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при удалении пользователя из проекта");
        }
    },
    async deleteProject({ dispatch }, id) {
        const toast = useToast();

        try {
            await deleteProject(id);
            dispatch('fetchProjects');
            toast.success("Проект успешно удален");
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при удалении проекта");
        }
    },
    async fetchProjects({ commit }) {
        const toast = useToast();

        try {
            const projects = await getAllProjects();
            commit('setProjects', projects);
        } catch (err) {
            commit('setProjectError', err);
            toast.error("Ошибка при загрузке проектов");
        }
    },
    async addFileToProject(_, { projectId, formData }) {
        const toast = useToast();

        try {
            const file = await uploadFile(projectId, formData);
            toast.success("Файл успешно загружен");
            return file;
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при загрузке файла");
        }
    },
    async fetchFile(_, fileId) {
        try {
            return await getFile(fileId);
        } catch (err) {
            console.log(err);
        }
    },
    async downloadFile(_, fileId) {
        const toast = useToast();

        try {
            const response = await downloadFile(fileId);
            const contentType = response.headers['content-type'];
            const contentDisposition = response.headers['content-disposition'];

            const matches = contentDisposition ? contentDisposition.match(/filename="(.+)"/) : null;
            const filename = matches && matches[1];

            const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при скачивании файла");
        }
    },
    async removeFileFromProject({ dispatch }, { projectId, fileId }) {
        const toast = useToast();

        try {
            await deleteFile(fileId);
            dispatch('fetchProject', projectId);
            toast.success("Файл успешно удален");
        } catch (err) {
            console.log(err);
            toast.error("Ошибка при удалении файла");
        }
    },
    async downloadAllFiles (_, projectId) {
        const toast = useToast();

        try {
            const response = await downloadAllFiles(projectId);

            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : `project-${projectId}-files.zip`;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Ошибка при скачивании архива:', error);
            toast.error("Ошибка при скачивании всех файлов");
        }
    },
    async addTask({ commit }, { projectId, description }) {
        const toast = useToast();

        try {
            const response = await addTask(projectId, description);
            commit('setProject', response);
        } catch (error) {
            console.error(error);
            toast.error("Ошибка при добавлении задачи");
        }
    },
    async updateTask({ commit }, { projectId, taskId, description, completed }) {
        const toast = useToast();

        try {
            const response = await updateTask(projectId, taskId, description, completed);
            commit('setProject', response);
        } catch (error) {
            console.error(error);
            toast.error("Ошибка при обновлении задачи");
        }
    },
    async deleteTask({ commit }, { projectId, taskId }) {
        const toast = useToast();

        try {
            const response = await deleteTask(projectId, taskId);
            commit('setProject', response);
        } catch (error) {
            console.error(error);
            toast.error("Ошибка при удалении задачи");
        }
    },
    async reorderTasks({ commit }, { projectId, tasks }) {
        try {
            await reorderTasks(projectId, tasks);
            commit('reorderTasks', { projectId, tasks });
        } catch (error) {
            console.error(error);
        }
    },
    async fetchMessages({ commit }, projectId) {
        const toast = useToast();

        try {
            const messages = await getMessages(projectId);
            commit('setMessages', messages);
        } catch (err) {
            console.error(err);
            toast.error("Ошибка при получении истории чата");
        }
    },
    async sendMessage({ commit }, { projectId, content }) {
        const toast = useToast();

        try {
            const message = await sendMessage(projectId, content);
            commit('addMessage', message);
        } catch (err) {
            console.error(err);
            toast.error("Ошибка при отправке сообщения");
        }
    }
};

const getters = {
    project: state => state.project,
    projects: state => state.projects,
    projectError: state => state.projectError,
    messages: state => state.messages
};

export default {
    mutations,
    getters,
    actions,
    state,
    namespaced: true
};
