<template>
    <div class="chat-wrapper">
        <div class="messages" ref="messagesContainer">
            <div
                v-for="message in messages"
                :key="message._id"
                :class="['message-wrapper', message.author ? (message.author._id === user._id ? 'my-message-wrapper' : 'other-message-wrapper') : 'system-message-wrapper']"
            >
                <div :class="['message', message.author ? (message.author._id === user._id ? 'my-message' : 'other-message') : 'system-message']">
                    <p v-if="message.author" class="author">{{ message.author.username }}</p>
                    <p class="content">
                        {{ message.content }}
                        <span v-if="message.author" class="timestamp">{{ new Date(message.timestamp).toLocaleTimeString() }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="message-input">
            <input v-model="newMessage" @keyup.enter="handleSendMessage" placeholder="Напишите сообщение..." />
            <button @click="handleSendMessage">Отправить</button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import io from 'socket.io-client';

export default {
    name: 'ChatComponent',
    data() {
        return {
            newMessage: '',
            socket: null
        };
    },
    computed: {
        ...mapGetters('auth', ['user']),
        ...mapGetters('project', ['messages'])
    },
    methods: {
        ...mapActions('project', ['fetchMessages', 'sendMessage']),
        async handleSendMessage() {
            if (this.newMessage.trim() === '') return;
            const message = { projectId: this.$route.params.id, content: this.newMessage, author: this.user };
            this.socket.emit('chat message', message);
            this.newMessage = '';
            this.scrollToBottom();
        },
        handleIncomingMessage(message) {
            this.$store.commit('project/addMessage', message);
            this.scrollToBottom();
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.messagesContainer;
                container.scrollTop = container.scrollHeight;
            });
        }
    },
    async created() {
        await this.fetchMessages(this.$route.params.id);
        this.scrollToBottom();
        this.socket = io('http://localhost:3000');
        this.socket.emit('join project', this.$route.params.id);
        this.socket.on('chat message', this.handleIncomingMessage);
        this.socket.on('system message', this.handleIncomingMessage);
    },
    beforeUnmount() {
        this.socket.emit('leave project', this.$route.params.id);
        this.socket.disconnect();
    }
};
</script>

<style scoped>
.chat-wrapper {
    display: flex;
    flex-direction: column;
    height: 500px;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 10px;
    background-color: #1e1e1e;
}

.messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 #333;
}

.messages::-webkit-scrollbar {
    width: 8px;
}

.messages::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.messages::-webkit-scrollbar-track {
    background-color: #333;
}

.message-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.my-message-wrapper {
    justify-content: flex-end;
}

.other-message-wrapper {
    justify-content: flex-start;
}

.system-message-wrapper {
    justify-content: center;
}

.message {
    max-width: 60%;
    min-width: 100px;
    padding: 10px;
    border-radius: 10px;
    word-wrap: break-word;
    position: relative;
}

.my-message {
    background-color: #343a40;
    color: white;
}

.other-message {
    background-color: #ffffff;
    color: black;
}

.system-message {
    color: #b9b9b9;
    text-align: center;
}

.author {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
}

.content {
    word-break: break-word;
    display: inline-block;
    margin: 0;
}

.timestamp {
    font-size: 0.8em;
    color: #888;
    float: right;
    width: 60px;
    text-align: right;
    margin-left: 10px;
    margin-top: 10px;
}

.message-input {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #444;
}

.message-input input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #121212;
    color: #fff;
}

.message-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
</style>
