<template>
    <div class="home">
        <div class="header">
            <h1>Мои проекты</h1>
        </div>
        <div class="columns">
            <div class="column">
                <h2>В работе</h2>
                <ProjectsList :projects="inProgressProjects" status="in-progress" @update-status="updateProjectStatus" @create-project="saveNewProject" />
            </div>
            <div class="column">
                <h2>Готово</h2>
                <ProjectsList :projects="completedProjects" status="completed" @update-status="updateProjectStatus" @create-project="saveNewProject" />
            </div>
            <div class="column">
                <h2>Архив</h2>
                <ProjectsList :projects="archivedProjects" status="archived" @update-status="updateProjectStatus" @create-project="saveNewProject" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList.vue';

export default {
    name: "HomeView",
    components: {
        ProjectsList
    },
    computed: {
        ...mapGetters('project', ['projects']),
        inProgressProjects() {
            return this.projects.filter(project => project.status === 'in-progress');
        },
        completedProjects() {
            return this.projects.filter(project => project.status === 'completed');
        },
        archivedProjects() {
            return this.projects.filter(project => project.status === 'archived');
        }
    },
    methods: {
        ...mapActions('project', ['fetchProjects', 'createProject', 'updateProject']),
        async updateProjectStatus({ project, status }) {
            if (project.status !== status) {
                project.status = status;
                await this.updateProject({ id: project._id, key: "status", data: { status } });
                this.fetchProjects();
            }
        },
        async saveNewProject(newProject) {
            await this.createProject(newProject);
            this.fetchProjects();
        }
    },
    mounted() {
        this.fetchProjects();
    }
};
</script>

<style scoped>
.home {
    background-color: #121212;
    color: #fff;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.columns {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}
.column {
    flex: 1;
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
}
.column h2 {
    margin-top: 0;
    color: #FFA500;
}
</style>
