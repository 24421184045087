<template>
    <div class="tasks">
        <div class="tasks-header">
            <h2>Список задач</h2>
            <button @click="toggleShowCompleted">{{ showCompleted ? 'Скрыть' : 'Показать' }} отмеченные</button>
        </div>
        <draggable
            v-model="filteredTasks"
            @change="onDragEnd"
            item-key="_id"
            tag="ul"
        >
            <template #item="{ element }">
                <li :key="element._id">
                    <input
                        type="checkbox"
                        v-model="element.completed"
                        @change="toggleTaskCompletion(element)"
                    />
                    <span
                        v-if="!editingTask || editingTask._id !== element._id"
                        @click="startEditingTask(element)"
                        :class="{ completed: element.completed }"
                    >
                        {{ element.description }}
                    </span>
                    <input
                        v-else
                        v-model="editingTaskDescription"
                        @blur="saveTaskEdit"
                        @keydown.enter.prevent="saveTaskEdit"
                    />
                    <button @click="handleDeleteTask(element._id)">Удалить</button>
                </li>
            </template>
        </draggable>
        <input
            type="text"
            v-model="newTaskDescription"
            @keyup.enter="handleAddTask"
            placeholder="Добавить новую задачу"
        />
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "TasksList",
    components: {
        draggable,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
        initialTasks: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            tasks: [...this.initialTasks],
            newTaskDescription: "",
            editingTask: null,
            editingTaskDescription: "",
            showCompleted: true,
        };
    },
    computed: {
        filteredTasks() {
            return this.showCompleted
                ? this.tasks
                : this.tasks.filter(task => !task.completed);
        }
    },
    methods: {
        handleAddTask() {
            if (this.newTaskDescription.trim() === "") return;
            this.$emit("add-task", { description: this.newTaskDescription });
            this.newTaskDescription = "";
        },
        startEditingTask(task) {
            this.editingTask = { ...task };
            this.editingTaskDescription = task.description;
        },
        saveTaskEdit() {
            if (this.editingTask) {
                this.$emit("update-task", {
                    taskId: this.editingTask._id,
                    description: this.editingTaskDescription,
                });
                this.editingTask = null;
            }
        },
        toggleTaskCompletion(task) {
            this.$emit("update-task", {
                taskId: task._id,
                completed: task.completed,
            });
        },
        handleDeleteTask(taskId) {
            this.$emit("delete-task", taskId);
        },
        onDragEnd() {
            this.$emit("reorder-tasks", this.tasks);
        },
        toggleShowCompleted() {
            this.showCompleted = !this.showCompleted;
        }
    },
    watch: {
        initialTasks(newTasks) {
            this.tasks = [...newTasks];
        },
    },
};
</script>

<style scoped>
.tasks {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 4px;
}

.tasks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.tasks ul {
    list-style: none;
    padding: 0;
}

.tasks li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tasks li span {
    margin-left: 10px;
    flex: 1;
    cursor: pointer;
}

.tasks li input[type="text"] {
    flex: 1;
    margin-left: 10px;
}

.tasks li button {
    background-color: #ff4500;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.tasks li button:hover {
    background-color: #ff6347;
}

span.completed {
    text-decoration: line-through;
    color: #888;
}

.tasks-header button {
    background-color: #FFA500;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.tasks-header button:hover {
    background-color: #FF4500;
}
</style>
