import { fetchUsers, createUser, updateUser, deleteUser, fetchRoles } from '@/services/auth.service';

const state = {
    users: [],
    roles: []
};

const mutations = {
    setUsers(state, users) {
        state.users = users;
    },
    setRoles(state, roles) {
        state.roles = roles;
    }
};

const actions = {
    async fetchUsers({ commit }) {
        try {
            const response = await fetchUsers();
            commit('setUsers', response.users);
        } catch (error) {
            console.log(error);
        }
    },
    async createUser({ dispatch }, userData) {
        try {
            await createUser(userData);
            dispatch('fetchUsers');
        } catch (error) {
            console.log(error);
        }
    },
    async updateUser({ dispatch }, { id, data }) {
        try {
            await updateUser(id, data);
            dispatch('fetchUsers');
        } catch (error) {
            console.log(error);
        }
    },
    async removeUser({ dispatch }, id) {
        try {
            await deleteUser(id);
            dispatch('fetchUsers');
        } catch (error) {
            console.log(error);
        }
    },
    async fetchRoles({ commit }) {
        try {
            const response = await fetchRoles();
            commit('setRoles', response.roles);
        } catch (error) {
            console.log(error);
        }
    }
};

const getters = {
    users: state => state.users,
    roles: state => state.roles
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
