import axios from 'axios';
import store from '@/store';
import router from '@/router';

const API_URL = "https://beatrow.ru/api/v1";

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    store.dispatch('auth/logout');
                    router.push('/login');
                    break;
                case 403:
                    router.push('/');
                    break;
            }

        }
        return Promise.reject(error);
    }
);

export const request = async ({ url, method, data, headers, responseType, needResponse }) => {
    try {
        const response = await instance.request({
            url,
            method,
            data,
            headers: headers || {
                'Content-Type': 'application/json'
            },
            responseType
        });

        return needResponse ? response : response.data;
    } catch (error) {
        return {};
    }
};

export const setDefaultsHeaders = (key, value) => {
    instance.defaults.headers.common[key] = value;
};

export const clearDefaultsHeaders = (key) => {
    delete instance.defaults.headers.common[key];
};