<template>
    <div class="project-item">
        <router-link :to="{ name: 'project', params: { id: project._id } }" class="project-link">
            <h3>{{ project.name }}</h3>
            <p>{{ project.description }}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'ProjectItem',
    props: {
        project: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.project-item {
    background-color: #333;
    color: #fff;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}
.project-item:hover {
    background-color: #444;
}
.project-link {
    color: #fff;
    text-decoration: none;
}
.project-link h3 {
    margin: 0;
    font-size: 20px;
}
.project-link p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #bbb;
}
</style>
