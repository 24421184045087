<template>
    <div class="login-page">
        <div class="login-form">
            <h2>Вход в систему</h2>
            <form @submit.prevent="handleLogin">
                <div class="form-group">
                    <label for="username">Логин:</label>
                    <input type="text" id="username" v-model="credentials.username" required>
                </div>
                <div class="form-group">
                    <label for="password">Пароль:</label>
                    <input type="password" id="password" v-model="credentials.password" required>
                </div>
                <button type="submit">Войти</button>
                <p v-if="authError" class="error">{{ authError }}</p>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "LoginForm",
    data() {
        return {
            credentials: {
                username: '',
                password: ''
            }
        };
    },
    computed: {
        ...mapGetters('auth', ['authError'])
    },
    methods: {
        ...mapActions('auth', ['login']),
        async handleLogin() {
            await this.login(this.credentials);
            if (!this.authError) {
                window.location.href = '/';
            }
        }
    }
}
</script>

<style scoped>
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #333 url('/src/assets/logo.png') 50% 20% / 25% no-repeat;
    position: relative;
    min-width: 100%;
}

.login-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.login-form {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-group {
    margin-bottom: 15px;
}

label, input {
    display: block;
    width: calc(100% - 18px);
}

input[type="text"], input[type="password"] {
    padding: 8px;
    margin-top: 5px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
}

input[type="text"]::placeholder, input[type="password"]::placeholder {
    color: #ddd;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #17a845;
}

.error {
    color: red;
    margin-top: 10px;
}
</style>
