import {
  login as authLogin,
  getMe,
  logout as authLogout,
} from "@/services/auth.service";

const state = () => ({
  user: null,
  token: null,
  authError: null,
});

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("token", token);
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setAuthError(state, error) {
    state.authError = error;
  },
  clearAuthData(state) {
    state.user = null;
    state.token = null;
    state.roles = null;
    localStorage.removeItem("token");
  },
};

const actions = {
  async login({ commit }, credentials) {
    try {
      const response = await authLogin(credentials);
      const { token, user } = response;
      commit("setUser", user);
      commit("setToken", token);
      commit("setRoles", user.roles);
    } catch (error) {
      commit(
        "setAuthError",
        error.response ? error.response.data.message : "Login error"
      );
    }
  },
  async fetchUser({ commit }) {
    const response = await getMe();
    const { user } = response;
    if (user) {
      commit("setUser", user);
      commit("setRoles", user.roles);
    }
  },
  logout({ commit }) {
    authLogout();
    commit("clearAuthData");
  },
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  isAdmin: (state) => state.roles && (state.roles.includes("ADMIN") || state.roles.includes("MANAGER")),
  user: (state) => state.user,
  authError: (state) => state.authError,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
