import { fetchNewUrl } from '@/services/file.service';

const state = () => ({
    isPlaying: false,
    volume: localStorage.getItem('audioVolume') ? parseInt(localStorage.getItem('audioVolume')) : 50,
    progress: 0,
    currentTime: 0,
    duration: 0,
    track: {
        title: '',
        artist: '',
        cover: null,
        audioUrl: null,
        projectId: null
    },
    audio: new Audio()
});

const mutations = {
    setTrack(state, track) {
        state.audio.src = track.audioUrl;
        state.track = track;
        state.isPlaying = true;
        state.audio.play();
    },
    setTrackUrl(state, newUrl) {
        state.audio.src = newUrl;
    },
    setDuration(state, duration) {
        state.duration = duration;
    },
    togglePlay(state) {
        if (!state.track.projectId) {
            return;
        }

        if (state.isPlaying) {
            state.audio.pause();
        } else {
            state.audio.play();
        }
        state.isPlaying = !state.isPlaying;
    },
    setVolume(state, volume) {
        state.volume = volume;
        state.audio.volume = volume / 100;
        localStorage.setItem('audioVolume', volume);
    },
    updateProgress(state) {
        state.currentTime = state.audio.currentTime;
        state.progress = (state.audio.currentTime / state.audio.duration) * 100;
    },
    resetPlayer(state) {
        state.isPlaying = false;
        state.progress = 0;
        state.currentTime = 0;
    }
};

const actions = {
    playTrack({ commit, state }, track) {
        state.audio.addEventListener('loadedmetadata', () => {
            commit('setDuration', state.audio.duration);
        });
        commit('setTrack', track);
    },
    togglePlay({ commit }) {
        commit('togglePlay');
    },
    changeVolume({ commit }, volume) {
        commit('setVolume', volume);
    },
    updateProgress({ commit }) {
        commit('updateProgress');
    },
    resetPlayer({ commit }) {
        commit('resetPlayer');
    },
    async fetchNewUrl({ commit }, fileId) {
        try {
            const url = await fetchNewUrl(fileId);
            commit('setTrackUrl', url);
        } catch (err) {
            console.log(err);
        }
    }
};

const getters = {
    isPlaying: (state) => state.isPlaying,
    volume: (state) => state.volume,
    progress: (state) => state.progress,
    duration: (state) => state.duration,
    track: (state) => state.track,
    audio: (state) => state.audio
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
