<template>
    <div id="app">
        <div v-if="isAuthenticated" class="main-wrapper">
            <side-nav class="side-nav" />
            <router-view class="main-content" />
        </div>
        <div v-else class="auth-wrapper">
            <router-view class="auth-content" />
        </div>
        <audio-player v-if="isAuthenticated" class="audio-player" />
    </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.min.css';

import { mapGetters } from 'vuex';
import SideNav from "@/components/SideNav";
import AudioPlayer from "@/components/AudioPlayer";

export default {
    name: "App",
    components: {
        SideNav,
        AudioPlayer
    },
    computed: {
        ...mapGetters('auth', ['isAuthenticated'])
    }
}
</script>

<style>
body,
html {
    padding: 0;
    margin: 0;
    height: 100%;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #111111;
    overflow: hidden;
}

.main-wrapper {
    display: flex;
    flex: 1;
}

.side-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh - 130px);
    width: 200px;
    background-color: #222;
    z-index: 1000;
}

.main-content {
    flex: 1;
    overflow-y: auto;
    margin-left: 240px;
    padding: 0 20px 110px 20px;
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.auth-content {
    width: 100%;
    max-width: 400px;
    padding: 20px;
}

.audio-player {
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #333;
    z-index: 1000;
}
</style>
