<template>
    <div class="audio-player">
        <div class="track-info">
            <img :src="track.cover || placeholderCover" alt="cover" class="cover" />
            <div class="track-details">
                <div class="track-title">{{ track.title }}</div>
                <div class="track-artist" @click="goToProject">{{ track.artist }}</div>
            </div>
        </div>
        <div class="controls">
            <button @click="prevTrack" class="control-button">
                <i class="fas fa-step-backward"></i>
            </button>
            <button @click="togglePlay" class="play-pause-button">
                <i :class="isPlaying ? 'fas fa-pause' : 'fas fa-play'"></i>
            </button>
            <button @click="nextTrack" class="control-button">
                <i class="fas fa-step-forward"></i>
            </button>
        </div>
        <div class="timeline-container">
            <span class="time-info">{{ formattedCurrentTime }}</span>
            <div class="progress-container" ref="timeline" @click="seekTrack" @mousemove="showHoverTime" @mouseleave="hideHoverTime">
                <div class="progress-bar" :style="{ width: progress + '%' }"></div>
                <div class="hover-time" v-if="showHover" :style="{ left: hoverX + 'px' }">{{ hoverTime }}</div>
            </div>
            <span class="time-info">{{ formattedDuration }}</span>
        </div>
        <div class="volume-control">
            <i class="fas fa-volume-down"></i>
            <input type="range" min="0" max="100" v-model="volume" @input="setVolume" />
            <i class="fas fa-volume-up"></i>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: "AudioPlayer",
    data() {
        return {
            placeholderCover: require("@/assets/placeholder.png"),
            showHover: false,
            hoverTime: '0:00',
            hoverX: 0
        };
    },
    computed: {
        ...mapState('audio', ['isPlaying', 'volume', 'progress', 'track', 'currentTime']),
        ...mapGetters('audio', ['audio', 'duration']),
        formattedCurrentTime() {
            return this.formatTime(this.currentTime);
        },
        formattedDuration() {
            return this.formatTime(this.duration || 0);
        }
    },
    methods: {
        ...mapActions('audio', ['togglePlay', 'fetchNewUrl', 'changeVolume', 'updateProgress', 'resetPlayer']),
        prevTrack() {},
        nextTrack() {},
        goToProject() {
            this.$router.push({ name: 'project', params: { id: this.track.projectId } });
        },
        setVolume(volume) {
            this.changeVolume(volume.target.value);
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const secs = Math.floor(seconds % 60);
            return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
        },
        showHoverTime(event) {
            const timeline = this.$refs.timeline;
            const rect = timeline.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            const percent = offsetX / rect.width;
            this.hoverTime = this.formatTime(this.duration * percent);
            this.hoverX = offsetX;
            this.showHover = true;
        },
        hideHoverTime() {
            this.showHover = false;
        },
        seekTrack(event) {
            const timeline = this.$refs.timeline;
            const rect = timeline.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            const percent = offsetX / rect.width;
            this.audio.currentTime = this.duration * percent;
        },
        async reloadTrack() {
            await this.fetchNewUrl(this.track.fileId);
            if (this.audio.src !== this.track.audioUrl) {
                this.track.audioUrl = this.audio.src;
                this.audio.play();
            } else {
                console.error('Unable to fetch new URL for track reload');
            }
        },
        handleAudioError() {
            this.reloadTrack();
        }
    },
    watch: {
        volume(newVolume) {
            this.changeVolume(newVolume);
        }
    },
    mounted() {
        this.changeVolume(this.volume);
        this.audio.addEventListener('timeupdate', this.updateProgress);
        this.audio.addEventListener('ended', this.resetPlayer);
        this.audio.addEventListener('error', this.handleAudioError);
    }
};
</script>

<style scoped>
.audio-player {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    padding: 10px 20px;
    width: calc(100% - 40px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.track-info {
    display: flex;
    align-items: center;
    width: 250px;
}

.cover {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 10px;
}

.track-details {
    display: flex;
    flex-direction: column;
}

.track-title {
    font-size: 14px;
    color: #FFA500;
}

.track-artist {
    font-size: 12px;
    color: #FF4500;
    cursor: pointer;
}

.controls {
    display: flex;
    align-items: center;
}

.control-button {
    opacity: 50%;
}

.control-button, .play-pause-button {
    width: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    margin: 0 10px;
    cursor: pointer;
}

.play-pause-button {
    font-size: 20px;
    color: #FFA500;
}

.timeline-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 20px;
}

.progress-container {
    flex: 1;
    height: 10px;
    background-color: #555;
    border-radius: 4px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
}

.progress-bar {
    height: 100%;
    background-color: #FFA500;
    border-radius: 4px;
}

.hover-time {
    position: absolute;
    bottom: 20px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    pointer-events: none;
}

.time-info {
    font-size: 12px;
    color: #FFA500;
}

.volume-control {
    display: flex;
    align-items: center;
}

.volume-control input[type="range"] {
    margin: 0 10px;
}

.volume-control i {
    color: white;
}
</style>
