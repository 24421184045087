<template>
    <div class="modal" v-if="visible">
        <div class="modal-content">
            <span class="close" @click="$emit('close')">&times;</span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalComponent',
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style scoped>
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
    background-color: #333;
    padding: 20px;
    border-radius: 4px;
    width: 80%;
    max-width: 500px;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
</style>
