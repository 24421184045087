import { fetchUsers } from '@/services/auth.service';

const state = {
    users: []
};

const mutations = {
    setUsers(state, users) {
        state.users = users;
    }
};

const actions = {
    async fetchUsers({ commit }) {
        try {
            const response = await fetchUsers();
            commit('setUsers', response.users);
        } catch (error) {
            console.log(error);
        }
    }
};

const getters = {
    users: state => state.users
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
};
