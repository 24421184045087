<template>
    <div class="loading-bar" v-if="isLoading"></div>
</template>

<script>
export default {
    name: 'LoadingBar',
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style scoped>
.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #1DB954;
    animation: loading 2s linear infinite;
}
@keyframes loading {
    0% {
        left: -100%;
        width: 100%;
    }
    50% {
        left: 25%;
        width: 50%;
    }
    100% {
        left: 100%;
        width: 0;
    }
}
</style>
