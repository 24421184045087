import { request } from '@/services/generic.service';

const createProject = (data) => request({
    url: 'project/create',
    method: 'post',
    data
});

const getAllProjects = () => request({
    url: 'project',
    method: 'get'
});

const getProject = (id) => request({
    url: `project/${id}`,
    method: 'get'
});

const updateProject = (id, key, data) => request({
    url: `project/${id}/${key}`,
    method: 'put',
    data
});

const addUserToProject = (projectId, userId) => request({
    url: `project/${projectId}/addUser/${userId}`,
    method: 'post'
});

const removeUserFromProject = (projectId, userId) => request({
    url: `project/${projectId}/removeUser/${userId}`,
    method: 'post'
});

const deleteProject = (id) => request({
    url: `project/${id}`,
    method: 'delete'
});

const addTask = (projectId, description) => request({
    url: 'project/tasks',
    method: 'post',
    data: { projectId, description }
});

const updateTask = (projectId, taskId, description, completed) => request({
    url: 'project/tasks',
    method: 'put',
    data: { projectId, taskId, description, completed }
});

const deleteTask = (projectId, taskId) => request({
    url: 'project/tasks',
    method: 'delete',
    data: { projectId, taskId }
});

const reorderTasks = (projectId, tasks) => request({
    url: 'project/tasks/reorder',
    method: 'put',
    data: { projectId, tasks }
});

const getMessages = (projectId) => request({
    url: `project/${projectId}/messages`,
    method: 'get'
});

const sendMessage = (projectId, content) => request({
    url: `project/${projectId}/messages`,
    method: 'post',
    data: { content }
});

export {
    createProject,
    getAllProjects,
    getProject,
    updateProject,
    addUserToProject,
    removeUserFromProject,
    deleteProject,
    addTask,
    updateTask,
    deleteTask,
    reorderTasks,
    getMessages,
    sendMessage
};