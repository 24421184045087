import { request } from '@/services/generic.service';

const uploadFile = (projectId, formData) => request({
  url: `file/upload/${projectId}`,
  method: 'post',
  data: formData,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

const getFile = (fileId) => request({
  url: `file/${fileId}`,
  method: 'get'
});

const downloadFile = (fileId) => request({
  url: `file/download/${fileId}`,
  method: 'get',
  responseType: 'blob',
  needResponse: true
});

const deleteFile = (fileId) => request({
  url: `file/${fileId}`,
  method: 'delete'
});

const downloadAllFiles = (projectId) => request({
  url: `file/downloadAll/${projectId}`,
  method: 'get',
  responseType: 'blob',
  needResponse: true
});

const fetchNewUrl = (fileId) => request({
  url: `file/updateUrl/${fileId}`,
  method: 'get'
});

export {
  uploadFile,
  getFile,
  downloadFile,
  deleteFile,
  downloadAllFiles,
  fetchNewUrl
};
