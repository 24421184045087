<template>
    <div class="files-list">
        <div class="actions">
            <div class="file-upload" @click="triggerFileUpload">
                <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;" />
                <i class="fas fa-upload"></i> Загрузить файл
            </div>
            <button @click="downloadAllFiles" class="download-all-button">
                <i class="fas fa-download"></i> Скачать все
            </button>
        </div>
        <div class="divider"></div>
        <div class="file-items-container" @dragover.prevent @drop.prevent="handleDrop" @dragenter="dragEnter" @dragleave="dragLeave" :class="{ 'dragging': isDragging }">
            <div class="file-items">
                <FileItem
                    v-for="file in files"
                    :key="file._id"
                    :file="file"
                    @download="downloadFile"
                    @delete="deleteFile"
                    @play="playFile"
                />
            </div>
            <div v-if="isDragging" class="drag-overlay">
                Отпустите файл чтобы загрузить
            </div>
        </div>
    </div>
</template>

<script>
import FileItem from './FileItem.vue';
import { mapActions } from 'vuex';

export default {
    name: 'FilesList',
    components: {
        FileItem
    },
    props: {
        files: {
            type: Array,
            required: true
        },
        projectId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isDragging: false
        };
    },
    methods: {
        ...mapActions('project', ['downloadFile', 'downloadAllFiles']),
        ...mapActions('audio', ['playTrack']),
        triggerFileUpload() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.$emit('upload', file);
            }
        },
        handleDrop(event) {
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                this.$emit('upload', files[i]);
            }
            this.isDragging = false;
        },
        downloadFile(fileId) {
            this.$store.dispatch('project/downloadFile', fileId);
        },
        deleteFile(fileId) {
            this.$emit('delete', fileId);
        },
        playFile(file) {
            const track = {
                title: file.name,
                artist: this.$store.getters['project/project'].name,
                cover: null,
                audioUrl: file.url,
                fileId: file._id,
                projectId: this.projectId
            };
            this.playTrack(track);
        },
        downloadAllFiles() {
            this.$store.dispatch('project/downloadAllFiles', this.projectId);
        },
        dragEnter() {
            this.isDragging = true;
        },
        dragLeave() {
            this.isDragging = false;
        }
    }
};
</script>

<style scoped>
.files-list {
    margin: 20px 0;
    padding: 20px;
    background-color: #2a2a2a;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.actions {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.file-upload:hover {
    background-color: #444;
}

.download-all-button {
    background-color: #555;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.download-all-button:hover {
    background-color: #666;
}

.divider {
    height: 1px;
    background-color: #444;
    margin: 10px 0;
}

.file-items-container.dragging {
    background-color: #3a3a3a;
}

.file-items-container {
    position: relative;
    max-height: 225px; /* 1.5 times the height of the file icon */
    overflow-y: auto;
    padding-bottom: 20px; /* Extra padding to ensure scroll visibility */
}

.file-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 225px;
    overflow-y: auto;
    padding-bottom: 20px;
    min-height: 50px;
}

.file-items::-webkit-scrollbar {
    width: 10px;
}

.file-items::-webkit-scrollbar-track {
    background: #2a2a2a;
}

.file-items::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 5px;
}

.file-items::-webkit-scrollbar-thumb:hover {
    background: #666;
}

.drag-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    pointer-events: none;
    text-align: center;
    transition: opacity 0.3s;
}
</style>
