import { createStore } from "vuex";
import auth from "@/store/auth";
import users from "@/store/users";
import project from "@/store/project";
import admin from "@/store/admin";
import audio from "@/store/audio";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    users,
    project,
    admin,
    audio
  },
});
