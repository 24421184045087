import { createApp } from "vue";

import App from "./App.vue";
// import "./registerServiceWorker"; // PWA temporarily DISABLE
import router from "./router";
import store from "./store";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import { setDefaultsHeaders } from '@/services/generic.service';

const app = createApp(App);

app.use(store).use(router).use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  icon: true,
  rtl: false
});

const token = localStorage.getItem("token");
if (token) {
  setDefaultsHeaders("Authorization", `Bearer ${token}`);
  store.commit("auth/setToken", token);
  await store.dispatch('auth/fetchUser');
}

app.mount("#app");