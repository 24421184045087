<template>
    <div class="manage-users">
        <div class="header">
            <h1>Управление пользователями</h1>
            <button @click="showCreateUserModal" class="create-button">Создать пользователя</button>
        </div>
        <ul>
            <li v-for="user in users" :key="user._id">
                {{ user.username }}
                <div class="actions">
                    <button @click="editUser(user)">Редактировать</button>
                    <button class="red" @click="confirmDeleteUser(user._id)">Удалить</button>
                </div>
            </li>
        </ul>

        <ModalComponent :visible="showModal" @close="hideModal">
            <div class="modal-header">
                <h2>{{ modalTitle }}</h2>
            </div>
            <div class="modal-body">
                <form @submit.prevent="handleSubmit">
                    <label for="username">Имя пользователя:</label>
                    <input type="text" v-model="form.username" />

                    <label for="password">Пароль:</label>
                    <input type="password" v-model="form.password" />

                    <label for="roles">Роли:</label>
                    <TagInput
                        class="tag-input"
                        v-model="form.roles"
                        :available-items="availableRoles"
                        placeholder="Добавить роль"
                        :allow-custom-tags="false"
                    />

                    <button type="submit">{{ modalButton }}</button>
                </form>
            </div>
        </ModalComponent>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent.vue';
import TagInput from '@/components/TagInput.vue';

export default {
    name: "ManageUsers",
    components: {
        ModalComponent,
        TagInput
    },
    data() {
        return {
            showModal: false,
            modalTitle: '',
            modalButton: '',
            form: {
                username: '',
                password: '',
                roles: []
            },
            editMode: false,
            editUserId: null
        };
    },
    computed: {
        ...mapGetters('admin', ['users', 'roles']),
        availableRoles() {
            return this.roles.map(role => ({ value: role.value, _id: role._id }));
        }
    },
    methods: {
        ...mapActions('admin', ['fetchUsers', 'createUser', 'updateUser', 'removeUser', 'fetchRoles']),
        showCreateUserModal() {
            this.resetForm();
            this.modalTitle = 'Создать пользователя';
            this.modalButton = 'Создать';
            this.showModal = true;
        },
        hideModal() {
            this.showModal = false;
        },
        editUser(user) {
            this.editMode = true;
            this.editUserId = user._id;
            this.form.username = user.username;
            this.form.roles = user.roles.map(role => ({ value: role.value, _id: role._id }));
            this.modalTitle = 'Редактировать пользователя';
            this.modalButton = 'Обновить';
            this.showModal = true;
        },
        async handleSubmit() {
            const formCopy = {
                ...this.form,
                roles: this.form.roles.map(role => role._id)
            };

            if (this.editMode) {
                await this.updateUser({ id: this.editUserId, data: formCopy });
            } else {
                await this.createUser(formCopy);
            }
            this.fetchUsers();
            this.hideModal();
        },
        async confirmDeleteUser(userId) {
            if (confirm("Вы уверены, что хотите удалить этого пользователя?")) {
                await this.deleteUser(userId);
                this.fetchUsers();
            }
        },
        async deleteUser(userId) {
            await this.removeUser(userId);
            this.fetchUsers();
        },
        async resetForm() {
            this.editMode = false;
            this.editUserId = null;
            this.form.username = '';
            this.form.password = '';
            this.form.roles = [];
            await this.fetchRoles();
        },
        updateRoles(roles) {
            this.form.roles = roles;
        }
    },
    async created() {
        await this.fetchUsers();
        await this.fetchRoles();
    }
};
</script>

<style scoped>
.manage-users {
    padding: 20px;
    background-color: #121212;
    color: #fff;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.create-button {
    background-color: #1DB954;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.create-button:hover {
    background-color: #17a845;
}
ul {
    list-style: none;
    padding: 0;
}
li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #333;
    border-radius: 4px;
}
.actions button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #1DB954;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.actions button:hover {
    background-color: #17a845;
}
.actions .red {
    background-color: #b91d1d;
}
.actions .red:hover {
    background-color: #a0171d;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-body {
    margin-top: 20px;
}
form label {
    display: block;
    margin-bottom: 8px;
    color: #fff;
}
form input,
form select {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
}
form .tag-input {
    margin-bottom: 10px;
}
form button {
    background-color: #1DB954;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
form button:hover {
    background-color: #17a845;
}
</style>
