<template>
    <div class="project-view">
        <LoadingBar :isLoading="isLoading" />
        <div v-if="!isLoading">
            <div class="header">
                <div class="title">
                    <h1
                        contenteditable="true"
                        @keydown.enter.prevent="updateField('name', $event.target.textContent)"
                        @blur="updateField('name', $event.target.textContent)"
                        class="editable"
                    >
                        {{ project.name }}
                    </h1>
                    <button class="settings-button" @click="showSettingsModal = true">Настройки</button>
                </div>
                <div class="divider"></div>
                <textarea
                    v-if="isEditingDescription"
                    v-model="editableDescription"
                    @blur="saveDescription"
                    class="editable-textarea"
                ></textarea>
                <p
                    v-else
                    @click="isEditingDescription = true"
                    class="editable description"
                >
                    {{ project.description }}
                </p>
                <div class="additional-info">
                    <div v-if="isEditingTempo">
                        <label>
                            Темп:
                            <input type="number" v-model="project.tempo" @blur="saveTempo" />
                        </label>
                    </div>
                    <p v-else @click="isEditingTempo = true">Темп: {{ project.tempo }}</p>

                    <div v-if="isEditingKey">
                        <label>
                            Тон:
                            <select v-model="project.key" @blur="saveKey">
                                <option v-for="note in notes" :key="note" :value="note">{{ note }}</option>
                            </select>
                        </label>
                    </div>
                    <p v-else @click="isEditingKey = true">Тон: {{ project.key }}</p>

                    <div v-if="isEditingScale">
                        <label>
                            Гамма:
                            <select v-model="project.scale" @blur="saveScale">
                                <option v-for="scale in scales" :key="scale" :value="scale">{{ scale }}</option>
                            </select>
                        </label>
                    </div>
                    <p v-else @click="isEditingScale = true">Гамма: {{ project.scale }}</p>
                </div>
            </div>
            <FilesList
                :files="project.files"
                :projectId="project._id"
                @upload="handleFileUpload"
                @delete="handleFileDelete"
            />
            <div class="content">
                <div class="tasks-list-container">
                    <TasksList
                        :projectId="project._id"
                        :initialTasks="project.tasks || []"
                        @add-task="handleAddTask"
                        @update-task="handleUpdateTask"
                        @delete-task="handleDeleteTask"
                        @reorder-tasks="handleReorderTasks"
                    />
                </div>
                <div class="chat-component">
                    <ChatComponent :projectId="project._id" />
                </div>
            </div>
        </div>
        <ModalComponent :visible="showSettingsModal" @close="showSettingsModal = false">
            <h2>Настройки проекта</h2>
            <TagInput
                class="users-list"
                v-model="members"
                :available-items="availableUsers"
                labelKey="username"
                @update:modelValue="updateProjectMembers"
                placeholder="Добавить участника"
            />
            <button @click="confirmDelete" class="delete-button">Удалить проект</button>
        </ModalComponent>
        <ModalComponent :visible="showConfirmDeleteModal" @close="showConfirmDeleteModal = false">
            <h2>Подтверждение удаления</h2>
            <p>Вы уверены, что хотите удалить этот проект? Это действие необратимо.</p>
            <div class="confirmation-buttons">
                <button @click="deleteCurrentProject" class="delete-button">Удалить</button>
                <button @click="showConfirmDeleteModal = false" class="cancel-button">Отмена</button>
            </div>
        </ModalComponent>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TagInput from '@/components/TagInput.vue';
import LoadingBar from '@/components/LoadingBar.vue';
import FilesList from '@/components/FilesList.vue';
import TasksList from '@/components/TasksList.vue';
import ChatComponent from '@/components/ChatComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    name: 'ProjectView',
    components: {
        TagInput,
        LoadingBar,
        FilesList,
        TasksList,
        ChatComponent,
        ModalComponent
    },
    data() {
        return {
            isLoading: true,
            availableUsers: [],
            members: [],
            isEditingDescription: false,
            editableDescription: '',
            showSettingsModal: false,
            showConfirmDeleteModal: false,
            isEditingTempo: false,
            isEditingKey: false,
            isEditingScale: false,
            notes: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
            scales: ['major', 'minor']
        };
    },
    computed: {
        ...mapGetters('project', ['project']),
        ...mapGetters('users', ['users'])
    },
    watch: {
        project(newProject) {
            this.editableDescription = newProject.description;
            this.members = newProject.members;
        }
    },
    methods: {
        ...mapActions('project', [
            'fetchProject',
            'updateProject',
            'addUserToProject',
            'removeUserFromProject',
            'addFileToProject',
            'removeFileFromProject',
            'addTask',
            'updateTask',
            'deleteTask',
            'reorderTasks',
            'deleteProject'
        ]),
        ...mapActions('users', [
            'fetchUsers'
        ]),
        async updateField(field, value, force) {
            if (this.project[field] !== value || force) {
                let data = {};
                data[field] = value;
                await this.updateProject({ id: this.project._id, key: field, data });
                this.project[field] = value;
            }
        },
        async saveTempo() {
            this.isEditingTempo = false;
            await this.updateField('tempo', this.project.tempo, true);
        },
        async saveKey() {
            this.isEditingKey = false;
            await this.updateField('key', this.project.key, true);
        },
        async saveScale() {
            this.isEditingScale = false;
            await this.updateField('scale', this.project.scale, true);
        },
        updateProjectMembers(newMembers) {
            if (this.project.members.length === newMembers.length) {
                return;
            }

            const addedMembers = newMembers.filter(user => !this.project.members.includes(user));
            const removedMembers = this.project.members.filter(user => !newMembers.includes(user));

            addedMembers.forEach(member => this.addUserToProject({ projectId: this.project._id, userId: member._id }));
            removedMembers.forEach(member => this.removeUserFromProject({
                projectId: this.project._id,
                userId: member._id
            }));

            this.fetchProject(this.project._id);
        },
        async handleFileUpload(file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const newFile = await this.addFileToProject({ projectId: this.project._id, formData });
                this.project.files.push(newFile);
            } catch (error) {
                console.error('Ошибка при загрузке файла:', error);
            }
        },
        async handleFileDelete(fileId) {
            try {
                await this.removeFileFromProject({ projectId: this.project._id, fileId });
                this.project.files = this.project.files.filter(file => file._id !== fileId);
            } catch (error) {
                console.error('Ошибка при удалении файла:', error);
            }
        },
        async saveDescription() {
            this.isEditingDescription = false;
            await this.updateField('description', this.editableDescription);
        },
        async fetchAvailableUsers() {
            try {
                await this.fetchUsers();
                this.availableUsers = this.users;
            } catch (error) {
                console.error('Ошибка при получении пользователей:', error);
            }
        },
        async handleAddTask({ description }) {
            await this.addTask({ projectId: this.project._id, description });
            this.fetchProject(this.project._id);
        },
        async handleUpdateTask({ taskId, description, completed }) {
            await this.updateTask({
                projectId: this.project._id,
                taskId,
                description,
                completed
            });
            this.fetchProject(this.project._id);
        },
        async handleDeleteTask(taskId) {
            await this.deleteTask({ projectId: this.project._id, taskId });
            this.fetchProject(this.project._id);
        },
        async handleReorderTasks(tasks) {
            await this.reorderTasks({ projectId: this.project._id, tasks: tasks });
            this.fetchProject(this.project._id);
        },
        confirmDelete() {
            this.showConfirmDeleteModal = true;
        },
        async deleteCurrentProject() {
            await this.deleteProject(this.project._id);
            this.showConfirmDeleteModal = false;
            this.$router.push('/');
        }
    },
    async beforeMount() {
        this.isLoading = true;
        await this.fetchProject(this.$route.params.id);
        await this.fetchAvailableUsers();
        this.isLoading = false;
    }
};
</script>

<style scoped>
.project-view {
    background-color: #121212;
    color: #fff;
}

.header {
    margin-bottom: 20px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1, .header p, .header textarea {
    cursor: pointer;
}

.header h1:focus, .header p:focus, .header textarea:focus {
    outline: none;
    border-bottom: 2px solid #FFA500;
}

.settings-button {
    background-color: #800080;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
}

.settings-button:hover {
    background-color: #FF4500;
}

.users-list {
    margin-bottom: 10px;
}

.description {
    background-color: #1e1e1e;
    padding: 10px;
    border-radius: 4px;
    white-space: pre-wrap;
}

.divider {
    height: 1px;
    background-color: #444;
    margin: 20px 0;
}

.additional-info {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.additional-info p {
    cursor: pointer;
    background-color: #1e1e1e;
    padding: 10px;
    border-radius: 4px;
}

.additional-info label {
    display: flex;
    flex-direction: column;
    color: #fff;
}

.additional-info input, .additional-info select {
    margin-top: 5px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #1e1e1e;
    color: #fff;
}

.content {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.tasks-list-container {
    flex: 1;
    max-width: 30%;
}

.chat-component {
    flex: 2;
    max-width: 70%;
}

.editable-textarea {
    width: 100%;
    min-height: 100px;
    background-color: #121212;
    color: #fff;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 10px;
    resize: vertical;
}

.delete-button {
    background-color: #ff4500;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #ff6347;
}

.cancel-button {
    background-color: #238300;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: #32b000;
}

.confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>
