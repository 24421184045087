import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import ProjectView from "@/views/ProjectView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

import ManageUsers from '@/views/admin/ManageUsers';

import LoginForm from "@/components/LoginForm.vue";
import store from '@/store';

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/login",
    name: "login",
    component: LoginForm,
    meta: { notRequiresAuth: true }
  },
  {
    path: "/project/:id",
    name: "project",
    component: ProjectView
  },
  {
    path: '/admin',
    name: 'manage-users',
    component: ManageUsers,
    meta: { requiresAdmin: true }
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFoundView
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => !record.meta.notRequiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.RequiresAdmin);
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const isAdmin = store.getters['auth/isAdmin'];

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (requiresAdmin && !isAdmin) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
