<template>
    <div class="projects-list-container">
        <draggable
            :list="localProjects"
            group="projects"
            :itemKey="status"
            @change="onChange"
            class="projects-list"
            :ghost-class="'ghost'"
            :chosen-class="'chosen'"
            :animation="200"
            :fallbackOnBody="true"
            :fallbackTolerance="10"
            :delay="0"
            :delayOnTouchOnly="true"
            :touchStartThreshold="4"
        >
            <template #item="{ element }">
                <ProjectItem :project="element" />
            </template>
        </draggable>
        <div class="footer">
            <div v-if="creatingProject" class="create-project-input">
                <textarea v-model="newProjectName" placeholder="Введите заголовок для этого проекта" @keyup.enter="createNewProject" />
                <div class="button-group">
                    <button @click="createNewProject" class="save-button">Добавить проект</button>
                    <button @click="cancelCreateProject" class="cancel-button">✖</button>
                </div>
            </div>
            <button @click="showCreateProjectInput" class="create-button" v-else>
                <i class="fas fa-plus"></i> Создать новый проект
            </button>
        </div>
    </div>
</template>

<script>
import ProjectItem from '@/components/ProjectItem.vue';
import draggable from 'vuedraggable';

export default {
    name: 'ProjectsList',
    components: {
        ProjectItem,
        draggable
    },
    props: {
        projects: {
            type: Array,
            required: true
        },
        status: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            localProjects: this.projects,
            creatingProject: false,
            newProjectName: ''
        };
    },
    watch: {
        projects(newProjects) {
            this.localProjects = newProjects;
        }
    },
    methods: {
        onChange(evt) {
            if (evt.added) {
                const project = this.localProjects[evt.added.newIndex];
                this.$emit('update-status', { project, status: this.status });
            }
        },
        showCreateProjectInput() {
            this.creatingProject = true;
            this.newProjectName = '';
        },
        createNewProject() {
            if (this.newProjectName.trim() !== '') {
                const newProject = {
                    _id: Date.now().toString(),
                    name: this.newProjectName.trim(),
                    description: '',
                    status: this.status,
                };
                this.localProjects.push(newProject);
                this.$emit('create-project', newProject);
                this.creatingProject = false;
                this.newProjectName = '';
            }
        },
        cancelCreateProject() {
            this.creatingProject = false;
            this.newProjectName = '';
        }
    }
};
</script>

<style scoped>
.create-button {
    background-color: #FFA500;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-button i {
    margin-right: 5px;
}

.create-button:hover {
    background-color: #FF4500;
}

.create-project-input {
    background-color: #222;
    padding: 10px 0;
    border-radius: 4px;
}

.create-project-input textarea {
    width: calc(100% - 10px);
    padding: 10px 5px;
    border-radius: 8px;
    border: 1px solid #444;
    background-color: #333;
    color: #fff;
    resize: none;
    height: 50px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.save-button {
    background-color: #1DB954;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #17a845;
}

.cancel-button {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
}

.cancel-button:hover {
    color: #e74c3c;
}

</style>
