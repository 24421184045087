<template>
    <div class="sidebar">
        <div class="logo" @click="goHome">
            <img src="@/assets/logo.png" alt="Логотип" />
        </div>
        <nav>
            <ul>
                <li><router-link to="/"><i class="fas fa-folder-open"></i> Проекты</router-link></li>
                <li v-if="isAdmin"><router-link to="/admin"><i class="fas fa-user-shield"></i> Управление пользователями</router-link></li>
            </ul>
        </nav>
        <button class="logout-button" @click="handleLogout">Выйти</button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "SideNav",
    computed: {
        ...mapGetters('auth', ['isAuthenticated', 'isAdmin'])
    },
    methods: {
        ...mapActions('auth', ['logout']),
        goHome() {
            this.$router.push('/');
        },
        async handleLogout() {
            await this.logout();
            window.location.href = '/';
        }
    }
};
</script>

<style scoped>
.sidebar {
    background-color: #333;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
}

.logo img {
    max-width: 100%;
    height: auto;
}

nav ul {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
}

nav ul li {
    margin-bottom: 10px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    width: 100%;
    border-radius: 5px;
    transition: background-color 0.3s;
}

nav ul li a:hover {
    background-color: #FF4500;
}

.logout-button {
    background-color: #FF4500;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: auto;
}

.logout-button:hover {
    background-color: #FF6347;
}
</style>
