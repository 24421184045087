<template>
    <div class="file-item">
        <div class="file-info" @click="playFile">
            <div class="file-icon">{{ fileExtension }}</div>
            <div class="file-name">{{ file.name }}</div>
        </div>
        <div class="file-actions">
            <button @click.stop="downloadFile" class="file-action-button">
                <i class="fas fa-download"></i>
            </button>
            <button @click.stop="deleteFile" class="file-action-button">
                <i class="fas fa-trash"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "FileItem",
    props: {
        file: {
            type: Object,
            required: true
        }
    },
    computed: {
        fileExtension() {
            return this.file.name.split('.').pop().toUpperCase();
        }
    },
    methods: {
        downloadFile() {
            this.$emit('download', this.file._id);
        },
        deleteFile() {
            this.$emit('delete', this.file._id);
        },
        playFile() {
            this.$emit('play', this.file);
        }
    }
};
</script>

<style scoped>
.file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.file-info {
    display: flex;
    align-items: center;
}

.file-icon {
    width: 50px;
    height: 50px;
    background-color: #FFA500; /* яркий оранжевый */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 10px;
}

.file-name {
    font-size: 14px;
    color: white;
}

.file-actions {
    display: flex;
    gap: 10px;
}

.file-action-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.file-action-button:hover {
    color: #FF4500; /* яркий розовый */
}
</style>
