<template>
    <div class="tag-input">
        <multiselect
            v-model="selectedTags"
            :options="filteredAvailableItems"
            :multiple="true"
            :taggable="allowCustomTags"
            :label="labelKey"
            :track-by="valueKey"
            :placeholder="placeholder"
            @tag="addTag"
            @remove="removeTag"
        >
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
    name: 'TagInput',
    components: {
        Multiselect
    },
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        availableItems: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        labelKey: {
            type: String,
            default: 'value'
        },
        valueKey: {
            type: String,
            default: '_id'
        },
        allowCustomTags: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectedTags: this.modelValue
        };
    },
    watch: {
        modelValue(newVal) {
            this.selectedTags = newVal;
        },
        selectedTags(newVal) {
            this.$emit('update:modelValue', newVal);
        }
    },
    computed: {
        filteredAvailableItems() {
            const selectedKeys = this.selectedTags.map(item => this.getKey(item));
            return this.availableItems.filter(item => !selectedKeys.includes(this.getKey(item)));
        }
    },
    methods: {
        addTag(newTag) {
            const newTagObject = {
                [this.labelKey]: newTag,
                [this.valueKey]: Date.now().toString() // Use any unique identifier
            };
            this.selectedTags.push(newTagObject);
        },
        removeTag(tag) {
            this.selectedTags = this.selectedTags.filter(existingTag => this.getKey(existingTag) !== this.getKey(tag));
        },
        getKey(item) {
            return item[this.valueKey];
        }
    }
};
</script>

<style scoped>
.tag-input {
    width: 100%;
}
.multiselect {
    width: 100%;
}
</style>
