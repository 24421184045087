import { request, setDefaultsHeaders, clearDefaultsHeaders } from '@/services/generic.service';

const login = (credentials) => {
    const data = request({
        url: 'auth/login',
        method: 'post',
        data: credentials
    });

    if (data.token) {
        setDefaultsHeaders('Authorization', `Bearer ${data.token}`);
    }
    return data;
};

const getMe = async () => {
    const data = await request({
        url: 'auth/me',
        method: 'get'
    });

    if (data.user && data.user.roles) {
        data.user.roles = data.user.roles.map((role) => role.value);
    }

    return data;
};

const logout = () => {
    localStorage.removeItem('token');
    clearDefaultsHeaders('Authorization');
};

const getUser = (id) => request({
    url: `auth/${id}`,
    method: 'get'
});

const fetchUsers = () => request({
    url: 'auth',
    method: 'get'
});

const createUser = (userData) => request({
    url: 'auth/',
    method: 'post',
    data: userData
});

const updateUser = (id, data) => request({
    url: `auth/${id}`,
    method: 'put',
    data
});

const deleteUser = (id) => request({
    url: `auth/${id}`,
    method: 'delete'
});

const fetchRoles = () => request({
    url: 'auth/roles',
    method: 'get'
});

export {
    login,
    getMe,
    logout,
    getUser,
    fetchUsers,
    createUser,
    updateUser,
    deleteUser,
    fetchRoles
};
